<template>
	<div class="share-dropdown">
		<v-menu offset-y rounded="lg">
			<template v-slot:activator="{ on, attrs }">
				<button class="share-dropdown__button" v-bind="attrs" v-on="on" :style="outlined ? 'border: 1px solid #d2d7d9' : ''">
					<img :src="white ? IconShareWhite : IconShare" alt="share" />
				</button>
			</template>
			<v-list>
				<a class="share-dropdown__item-link" target="_blank" :href="shareFacebook()">
					<v-list-item class="share-dropdown__item">
						<v-list-item-title class="share-dropdown__item-container">
							<img class="share-dropdown__item-icon" :src="IconFacebook" alt="facebook" />
							<span class="share-dropdown__item-title">Facebook</span>
						</v-list-item-title>
					</v-list-item>
				</a>
				<a class="share-dropdown__item-link" target="_blank" :href="shareTwitter()">
					<v-list-item class="share-dropdown__item">
						<v-list-item-title class="share-dropdown__item-container">
							<img class="share-dropdown__item-icon" :src="IconTwitter" alt="twitter" />
							<span class="share-dropdown__item-title">Twitter</span>
						</v-list-item-title>
					</v-list-item>
				</a>
				<a class="share-dropdown__item-link" target="_blank" :href="shareWhatsApp()">
					<v-list-item class="share-dropdown__item">
						<v-list-item-title class="share-dropdown__item-container">
							<img class="share-dropdown__item-icon" :src="IconWhatsApp" alt="whatsapp" />
							<span class="share-dropdown__item-title">WhatsApp</span>
						</v-list-item-title>
					</v-list-item>
				</a>
				<a class="share-dropdown__item-link" target="_blank" :href="shareEmail()">
					<v-list-item class="share-dropdown__item">
						<v-list-item-title class="share-dropdown__item-container">
							<img class="share-dropdown__item-icon" :src="IconEmail" alt="email" />
							<span class="share-dropdown__item-title">Email</span>
						</v-list-item-title>
					</v-list-item>
				</a>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import { getProductInfo } from '@/api/product.js';
import { getExhibitorInfo } from '@/api/exhibitor.js';

export default {
	name: 'ShareDropdown',

	props: {
		url: {
			type: String,
			default: window.location.href
		},
		white: Boolean,
		outlined: Boolean,
		exhibitorName: {
			type: String,
			default: null
		},
		productName: {
			type: String,
			default: null
		}
	},

	data() {
		return {
			IconShare: require('@/assets/icons/share.svg'),
			IconShareWhite: require('@/assets/icons/share-white.svg'),
			IconEmail: require('@/assets/icons/social/email.svg'),
			IconWhatsApp: require('@/assets/icons/social/whatsapp.svg'),
			IconFacebook: require('@/assets/icons/social/facebook.svg'),
			IconTwitter: require('@/assets/icons/social/twitter.svg'),
			inExhibitor: false,
			inProduct: false,
			eventName: null
		};
	},

	mounted() {
		this.getInfo();
	},

	watch: {
		url: 'getInfo'
	},

	methods: {
		getInfo: async function () {
			const url = new URL(this.url);
			const parts = url.pathname.split('/');
			this.eventName = parts[2].toUpperCase();

			if (parts[3] === 'products') {
				this.inProduct = true;
				if (!this.productName || !this.exhibitorName) {
					const product = await getProductInfo(parts[4]);
					const exhibitor = await getExhibitorInfo(product.prsrexhibitorid);
					if (!this.exhibitorName) {
						this.productName = product.pstrname.toUpperCase;
						this.exhibitorName = exhibitor.exhiname.toUpperCase();
					}
				}
			} else if (parts[3] === 'exhibitors') {
				this.inExhibitor = true;
				if (!this.exhibitorName) {
					const exhibitor = await getExhibitorInfo(parts[4]);
					this.exhibitorName = exhibitor.exhiname.toUpperCase();
				}
			}
		},

		shareEmail: function () {
			if (this.inExhibitor) {
				let subject = this.exhibitorName + ' está participando en ' + this.eventName + '.';
				let body = 'Puedes ver la información de participación en ' + this.url;
				subject = encodeURI(subject);
				body = encodeURI(body);
				return 'mailto:?subject=' + subject + '&body=' + body;
			} else {
				return 'mailto:?subject=' + '' + '&body=' + this.url;
			}
		},

		shareWhatsApp: function () {
			if (this.inExhibitor) {
				let text =
					this.exhibitorName + ' está participando en ' + this.eventName + '. Puedes ver la información de participación en ' + this.url;
				text = encodeURI(text);
				return 'https://wa.me/?text=' + text;
			} else {
				return 'https://wa.me/?text=' + this.url;
			}
		},

		shareFacebook: function () {
			if (this.inExhibitor) {
				let text =
					this.exhibitorName + ' está participando en ' + this.eventName + '. Puedes ver la información de participación en el enlace.';
				text = encodeURI(text);
				return 'https://www.facebook.com/sharer/sharer.php?u=' + this.url + '&t=' + text;
			} else {
				return 'https://www.facebook.com/sharer/sharer.php?u=' + this.url;
			}
		},

		shareTwitter: function () {
			if (this.inExhibitor) {
				let text = this.exhibitorName + ' está participando en ' + this.eventName + '. Puedes ver la información de participación en ';
				text = encodeURI(text);
				return 'http://twitter.com/intent/tweet?text=' + text + '&url=' + this.url + '&hashtags=' + this.exhibitorName + ',' + this.eventName;
			} else {
				return 'http://twitter.com/intent/tweet?text=' + '&url=' + this.url;
			}
		}
	}
};
</script>


<style lang="scss" scoped>
.share-dropdown {
	&__button {
		border-radius: 8px;
		padding: 0.75rem;
		max-height: 2.5rem;
		display: flex;
		align-items: center;
		margin: 0 0.25rem;
	}

	&__item {
		cursor: pointer;
		padding-right: 4rem;

		&:hover {
			background-color: #f3f5f5;
		}

		&-container {
			display: flex;
			align-items: center;
		}

		&-icon {
			width: 1.5rem;
			height: auto;
			margin-right: 1rem;
		}

		&-link {
			text-decoration: none;
			color: #575e60;
		}
	}
}
</style>
