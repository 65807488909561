import Pui9HttpRequests from 'pui9-requests';

export async function getExhibitorInfo(id) {
	const controller = `/exhibitor/get?exhiid=${id}`;
	let data = [];

	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function getExhibitorBySector(body) {
	const controller = `/vcatalogexhibitor/obtainBySector`;
	let data = [];
	await Pui9HttpRequests.postRequest(
		controller,
		body,
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}



export async function IncrementLinkCounter(id) {
	const controller = `/exhibitor/incrementLinkCounter?exhiid=${id}`;
	let data = [];

	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}
