import Pui9HttpRequests from 'pui9-requests';

export async function getProductInfo(id) {
	const controller = `/productservice/get?prsrid=${id}`;
	let data = [];

	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}


